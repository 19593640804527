import { Button, DatePicker, Input } from 'antd';
import { useEffect, useState } from 'react';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';

import { EyeOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useParams } from 'react-router-dom';
import { ILogCleanDTO, IMensagemHomeDTO } from '../../models/happy-code-api.model';
import { useLogService } from '../../services/log.service';
import { useMensagemHomeService } from '../../services/mensagem-home';
import { useNotificationService } from '../../services/notification.service';
import { formatMomentLocalDateTime, toMoment } from '../../util/format';
import ModalMensagemHome from './modal-preview';
import './style.css';
import useMensagemHome from './useMensagemHome';

const MensagemHomeCreate: React.FunctionComponent = () => {
	const { id } = useParams<any>();
	const [mensagemHome, setMensagemHome] = useState<IMensagemHomeDTO>();
	const [open, setOpen] = useState<boolean>(false);
	const mensagemHomeService = useMensagemHomeService();
	const notification = useNotificationService();
	const { setIsGlobalLoading } = useGlobalContext();
	const { uploadPlugin, validationFields } = useMensagemHome();

	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso mensagem home',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const findMensagemById = () => {
		setIsGlobalLoading(true);
		mensagemHomeService
			.findById({ id: id })
			.then(({ data }) => data && setMensagemHome(data))
			.finally(() => setIsGlobalLoading(false));
	};

	const saveMensagemHome = () => {
		setIsGlobalLoading(true);
		if (mensagemHome?.id) {
			mensagemHomeService
				.patch({ id: mensagemHome.id, mensagem: mensagemHome.mensagem, dataVencimento: mensagemHome.dataVencimento, titulo: mensagemHome.titulo })
				.finally(() => setIsGlobalLoading(false));
		}
		mensagemHomeService.create(mensagemHome).finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (id) {
			findMensagemById();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const save = () => {
		const isValid = validationFields(mensagemHome);
		if (!isValid) {
			return;
		}
		try {
			saveMensagemHome();
			notification({ message: 'Sucesso!', description: 'Alterações feitas com sucesso!' });
			history.back();
		} catch (error) {
			notification({ message: 'Falha!', description: 'houve um problema na alteração!' });
		}
	};

	return (
		<div className="container">
			<ModalMensagemHome content={mensagemHome} open={open} setOpen={setOpen} />
			<NewMenu selecionado={29} />
			<div className="dashboard" style={{ backgroundColor: '#f5f5f9' }}>
				<div className="autonomia-row">
					<div className="boxes-autonomia" style={{ alignItems: 'center' }}>
						<div className="box-autonomia" style={{ alignItems: 'center' }}>
							<div className="title">
								<h3>Mensagem home</h3>
							</div>
							<div className="line" style={{ marginTop: 10, marginBottom: 10 }}></div>
							<div className="container-header">
								<h5>Mensagem na tela da home dos hubs</h5>
							</div>
							<div className="container-inputs">
								<div className="input-box" style={{ marginTop: 10, height: 'auto' }}>
									<label htmlFor="titulo">
										<p>Titulo da mensagem</p>
									</label>
									<Input
										id="titulo"
										maxLength={40}
										placeholder="Titulo"
										value={mensagemHome?.titulo ? mensagemHome?.titulo : ''}
										onChange={(e) => setMensagemHome((prev) => ({ ...prev, titulo: e.target.value }))}
									/>
								</div>
								<div className="input-box">
									<label htmlFor="dataVencimento">
										<p>Data de expiração</p>
									</label>
									<DatePicker
										id="dataVencimento"
										disabledDate={(d) => d.isBefore(new Date(), 'day')}
										onChange={(e) =>
											setMensagemHome((prev) => {
												return { ...prev, dataVencimento: formatMomentLocalDateTime(e) };
											})
										}
										style={{ width: '100%' }}
										placeholder="Data de expiração da mensagem"
										format="DD/MM/YYYY"
										value={toMoment(mensagemHome?.dataVencimento)}
									/>
								</div>
							</div>
							<div className="input-box" style={{ marginTop: 10, height: 'auto' }}>
								<CKEditor
									config={{
										extraPlugins: [uploadPlugin],
										mediaEmbed: {
											previewsInData: true,
										},
										toolbar: {
											removeItems: ['heading', 'bulletedList', 'numberedList', 'alignment', 'decreaseIndent', 'increaseIndent', 'insertTable', 'blockQuote'],
										},
									}}
									editor={ClassicEditor}
									data={mensagemHome?.mensagem}
									onChange={(_, editor) => {
										setMensagemHome((prev) => {
											let data = editor.getData();
											const linkRegex = /<a.*?href="(.*?)".*?>(.*?)<\/a>/g;
											data = data.replace(linkRegex, `<a target="_blank" href="$1">Clique aqui</a>`);
											return { ...prev, mensagem: data };
										});
									}}
								/>
							</div>
							<div><small style={{ color: 'red', fontSize: 14}}>tamanho de imagem recomendado: 600 x 400</small></div>
							<div className="botoes" id="botoes-auto" style={{ justifyContent: 'flex-end' }}>
								<Button className="button-second" onClick={() => history.back()} style={{ width: 160, marginRight: 10 }}>
									Voltar e cancelar
								</Button>
								<Button className="button-primary" id="botao-salvar-autonomia" onClick={save} style={{ width: 180 }}>
									Salvar Alterações
								</Button>
								<Button onClick={() => setOpen(true)} className="button-primary" id="botao-salvar-autonomia" style={{ width: 100, margin: 5 }}>
									Preview <EyeOutlined rev={true} />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MensagemHomeCreate;
